export const spribeData = {
  Aviator: {
    redirectUrl: "/casino/spribe/aviator",
    imgUrl: "./images/spribe/03.png",
    alt: "aviator",
    name: "Aviator",
  },
  Mines: {
    redirectUrl: "/casino/spribe/mines",
    imgUrl: "./images/spribe/02.jpg",
    alt: "mines",
    name: "Mines",
  },
  MiniRoulette: {
    redirectUrl: "/casino/spribe/miniroulette",
    imgUrl: "./images/spribe/04.png",
    alt: "mini roulette",
    name: "Mini Roulette",
  },
  Hilo: {
    redirectUrl: "/casino/spribe/hilo",
    imgUrl: "./images/spribe/05.png",
    alt: "hilo",
    name: "Hilo",
  },
  Goal: {
    redirectUrl: "/casino/spribe/goal",
    imgUrl: "./images/spribe/06.png",
    alt: "goal",
    name: "Goal",
  },
  Dice: {
    redirectUrl: "/casino/spribe/dice",
    imgUrl: "./images/spribe/07.jpg",
    alt: "dice",
    name: "Dice",
  },
  Plinko: {
    redirectUrl: "/casino/spribe/plinko",
    imgUrl: "./images/spribe/08.jpg",
    alt: "plinko",
    name: "Plinko",
  },
};
