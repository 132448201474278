import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const BottomHead = () => {
  const navigate = useNavigate();
  const headerItems = [
    {
      id: 1,
      name: "Home",
      key: "home",
      redirectionUrl: "/sports",
      // image: HomeICon,
      authRequired: false,
    },
    {
      id: 2,
      name: "In-Play",
      key: "inplay",
      redirectionUrl: "/spmsports/cricket",
      // image: Inplay,
      authRequired: false,
    },
    {
      id: 3,
      name: "Cricket",
      key: "cricket",
      redirectionUrl: "/spmsports/cricket",
      // image: Cricket,
      authRequired: false,
    },
    {
      id: 4,
      name: "Footbal",
      key: "footbal",
      redirectionUrl: "/spmsports/soccer",
      // image: Soccer,
      authRequired: false,
    },
    {
      id: 5,
      name: "Tennis",
      key: "Tennis",
      redirectionUrl: "/spmsports/tennis",
      // image: Tennis,
      authRequired: false,
    },
    {
      id: 6,
      name: "Casino",
      key: "casino",
      redirectionUrl: "/allCasino",
      // image: Horse,
      authRequired: false,
    },
    {
      id: 7,
      name: "Horse Racing",
      key: "horse",
      redirectionUrl: "/spmsports/horse-racing",
      // image: Horse,
      authRequired: false,
    },

    {
      id: 9,
      name: "Exchange",
      key: "ipl",
      redirectionUrl: "/spmsports/cricket",
      // image: EvolutionImg,
      authRequired: false,
    },

    {
      id: 12,
      name: "Sports Book",
      key: "sportsbook",
      redirectionUrl: "/betby",
      // image: EvolutionImg,
      authRequired: false,
    },
    {
      id: 13,
      name: "Kingmaker",
      key: "kingmaker",
      redirectionUrl: "/kingmaker",
      // image: EvolutionImg,
      authRequired: false,
    },
  ];
  const location = useLocation();

  const checkIfActive = (key) => {
    // console.log("location", location);
    return key === location?.state?.activePage;
  };

  return (
    <div className="Bottom_head">
      <ul>
        {Array.isArray(headerItems) &&
          headerItems.map((item, index) => {
            return (
              <li className="" key={index}>
                {/* <img
                src={item?.image}
                alt="AvitorImg"
                className="d-md-none d-block"
              /> */}
                <a
                  onClick={() => {
                    if (item?.authRequired === false) {
                      navigate(item.redirectionUrl, {
                        state: {
                          activePage: item?.key,
                        },
                      });
                    } else {
                      window.location.href = "/sign-in";
                    }
                  }}
                  className={checkIfActive(item?.key) ? "active" : ""}
                >
                  {item?.name}
                </a>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default BottomHead;
