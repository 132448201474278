export const kingMakerData = [
  {
    redirectUrl: "/casino/km-5-card-poker",
    imgUrl: "./images/kingmakerGames/5cardpoker.jpg",
    alt: "5cardpoker",
    name: "5 Cardpoker ",
    providerName: "Kingmaker",
  },
  {
    redirectUrl: "/casino/km-7-up-7-down",
    imgUrl: "./images/kingmakerGames/7up7down.jpg",
    alt: "7up7down",
    name: "7 Up 7 down ",
  },
  {
    redirectUrl: "/casino/km-32-cards",
    imgUrl: "./images/kingmakerGames/32cards.jpg",
    alt: "32cards",
    name: "32 Cards",
  },
  {
    redirectUrl: "/casino/km-andar-bahar",
    imgUrl: "./images/kingmakerGames/Andarbahar.jpg",
    alt: "Andarbahar",
    name: "Andar Bahar",
  },
  {
    redirectUrl: "/casino/km-baccarat",
    imgUrl: "./images/kingmakerGames/Baccarat.jpg",
    alt: "Baccarat",
    name: "Baccarat",
  },
  {
    redirectUrl: "/casino/km-bai-buu",
    imgUrl: "./images/kingmakerGames/Baibuu.jpg",
    alt: "Baibuu",
    name: "Bai buu",
  },
  {
    redirectUrl: "/casino/km-bai-cao",
    imgUrl: "./images/kingmakerGames/Baicao.jpg",
    alt: "Baicao",
    name: "Bai cao",
  },
  {
    redirectUrl: "/casino/km-belangkai-2",
    imgUrl: "./images/kingmakerGames/Bengkai2.jpg",
    alt: "Bengkai2",
    name: "Belangkai 2",
  },
  {
    redirectUrl: "/casino/km-blackjack",
    imgUrl: "./images/kingmakerGames/Blackjack.jpg",
    alt: "Blackjack",
    name: "Blackjack",
  },
  {
    redirectUrl: "/casino/km-bola-golek",
    imgUrl: "./images/kingmakerGames/Bolagolek.jpg",
    alt: "Bolagolek",
    name: "Bola golek",
  },
  {
    redirectUrl: "/casino/km-bola-tangkas",
    imgUrl: "./images/kingmakerGames/Bolatangakas.jpg",
    alt: "Bolatangakas",
    name: "Bola tangkas",
  },
  {
    redirectUrl: "/casino/km-bonus-dice",
    imgUrl: "./images/kingmakerGames/Bonusdice.jpg",
    alt: "Bonusdice",
    name: "Bonus dice",
  },
  {
    redirectUrl: "/casino/km-card-hi-lo",
    imgUrl: "./images/kingmakerGames/Cardshilo.jpg",
    alt: "Cardshilo",
    name: "Cards hi lo",
  },
  {
    redirectUrl: "/casino/km-cash-rocket",
    imgUrl: "./images/kingmakerGames/CashRocket.jpg",
    alt: "CashRocket",
    name: "Cash Rocket",
  },
  {
    redirectUrl: "/casino/km-cleopatras-treasure",
    imgUrl: "./images/kingmakerGames/Cleopatatreasure.jpg",
    alt: "CashRocket",
    name: "Cleopatar'streasure",
  },
  {
    redirectUrl: "/casino/km-cockfighting-arena",
    imgUrl: "./images/kingmakerGames/CockFighting.jpg",
    alt: "CockFighting",
    name: "Cock Fighting",
  },
  {
    redirectUrl: "/casino/km-coin-pusher",
    imgUrl: "./images/kingmakerGames/Coinpusher.jpg",
    alt: "CockFighting",
    name: "Coin Pusher",
  },
  {
    redirectUrl: "/casino/km-coin-toss",
    imgUrl: "./images/kingmakerGames/CoinToss.jpg",
    alt: "km-coin-toss",
    name: "Coin Toss",
  },
  {
    redirectUrl: "/casino/km-color-game",
    imgUrl: "./images/kingmakerGames/Colourgame.jpg",
    alt: "Colourgame",
    name: "Color game",
  },
  {
    redirectUrl: "/casino/km-dota-hi-lo",
    imgUrl: "./images/kingmakerGames/Dotahilo.jpg",
    alt: "Dotahilo",
    name: "Dota hi lo",
  },
  {
    redirectUrl: "/casino/km-dragon-tiger-2",
    imgUrl: "./images/kingmakerGames/dragontiger.jpg",
    alt: "dragontiger",
    name: "Dragon tiger",
  },
  {
    redirectUrl: "/casino/km-elite-aviator-club",
    imgUrl: "./images/kingmakerGames/Eliteaviatorclub.jpg",
    alt: "Eliteaviatorclub",
    name: "Elite aviator club",
  },
  {
    redirectUrl: "/casino/km-european-roulette",
    imgUrl: "./images/kingmakerGames/EuropeanRoulette.jpg",
    alt: "EuropeanRoulette",
    name: "European Roulette",
  },
  {
    redirectUrl: "/casino/km-fan-tvan-classic",
    imgUrl: "./images/kingmakerGames/Fantanclassic.jpg",
    alt: "Fantanclassic",
    name: "Fantan classic",
  },
  {
    redirectUrl: "/casino/km-fruit-roulette",
    imgUrl: "./images/kingmakerGames/Fruitroulette.jpg",
    alt: "Fruitroulette",
    name: "Fruit Roulette",
  },
  {
    redirectUrl: "/casino/km-gems-of-valhalla",
    imgUrl: "./images/kingmakerGames/Gemsofvallhala.jpg",
    alt: "Gemsofvallhala",
    name: "Gems of vallhala",
  },
  {
    redirectUrl: "/casino/km-gold-mines",
    imgUrl: "./images/kingmakerGames/Goldmine.jpg",
    alt: "Goldmine",
    name: "Gold mine",
  },
  {
    redirectUrl: "/casino/km-heist",
    imgUrl: "./images/kingmakerGames/heist.jpg",
    alt: "heist",
    name: "Heist",
  },
  {
    redirectUrl: "/casino/km-interstellar-run",
    imgUrl: "./images/kingmakerGames/InterstellarRun.jpg",
    alt: "InterstellarRun",
    name: "Interstellar Run",
  },
  {
    redirectUrl: "/casino/km-jackpot-jump",
    imgUrl: "./images/kingmakerGames/JackpotJump.jpg",
    alt: "JackpotJump",
    name: "Jackpot Jump",
  },
  {
    redirectUrl: "/casino/km-jhandi-munda",
    imgUrl: "./images/kingmakerGames/JhandiMunda.jpg",
    alt: "JhandiMunda",
    name: "Jhandi Munda",
  },
  {
    redirectUrl: "/casino/km-thai-fish-prawn-crab",
    imgUrl: "./images/kingmakerGames/Kingmidas.jpg",
    alt: "Thai Fish Prawn Crab	",
    name: "Thai Fish Prawn Crab	",
  },
  {
    redirectUrl: "/casino/km-virtual-animal-race",
    imgUrl: "./images/kingmakerGames/KM-Virtual-Animal.jpg",
    alt: "Virtual Animal Race",
    name: " Animal Race",
  },
  {
    redirectUrl: "/casino/km-ludo",
    imgUrl: "./images/kingmakerGames/Ludo.jpg",
    alt: "Ludo",
    name: "Ludo",
  },
  {
    redirectUrl: "/casino/km-marble-racing",
    imgUrl: "./images/kingmakerGames/Marbleknockout.jpg",
    alt: "Marbleknockout",
    name: "Marble knockout",
  },
  {
    redirectUrl: "/casino/km-max-keno",
    imgUrl: "./images/kingmakerGames/maxkeno.jpg",
    alt: "maxkeno",
    name: "Maxkeno",
  },
  {
    redirectUrl: "/casino/km-minesweeper",
    imgUrl: "./images/kingmakerGames/Minesweeper.jpg",
    alt: "Minesweeper",
    name: "Mine sweeper",
  },
  {
    redirectUrl: "/casino/km-monkey-king-roulette",
    imgUrl: "./images/kingmakerGames/MonkeyKingRoulette.jpg",
    alt: "MonkeyKingRoulette",
    name: "Monkey King Roulette",
  },
  {
    redirectUrl: "/casino/km-olympus-glory",
    imgUrl: "./images/kingmakerGames/Olyumpusglory.jpg",
    alt: "Olyumpusglory",
    name: "Olyumpus glory",
  },
  {
    redirectUrl: "/casino/km-pai-kang",
    imgUrl: "./images/kingmakerGames/Paikang.jpg",
    alt: "Paikang",
    name: "Pai kang",
  },
  {
    redirectUrl: "/casino/km-penguin-panic",
    imgUrl: "./images/kingmakerGames/Penguinpanic.jpg",
    alt: "Penguinpanic",
    name: "Penguin panic",
  },
  {
    redirectUrl: "/casino/km-plinko",
    imgUrl: "./images/kingmakerGames/Plinko.jpg",
    alt: "Plinko",
    name: "Plinko",
  },
  {
    redirectUrl: "/casino/km-km-pok-deng",
    imgUrl: "./images/kingmakerGames/Pokdeng.jpg",
    alt: "Pokdeng",
    name: "Pok deng",
  },
  {
    redirectUrl: "/casino/km-poker-roulette",
    imgUrl: "./images/kingmakerGames/Pokerroulette.jpg",
    alt: "Pokerroulette",
    name: "Poker roulette",
  },
  {
    redirectUrl: "/casino/km-pusoy",
    imgUrl: "./images/kingmakerGames/Pusoy.jpg",
    alt: "Pusoy",
    name: "Pusoy",
  },
  {
    redirectUrl: "/casino/km-rooster-blitz",
    imgUrl: "./images/kingmakerGames/Roosterblitz.jpg",
    alt: "Roosterblitz",
    name: "Rooster blitz",
  },
  {
    redirectUrl: "/casino/km-sicbo",
    imgUrl: "./images/kingmakerGames/sicbo.jpg",
    alt: "sicbo",
    name: "Sicbo",
  },
  {
    redirectUrl: "/casino/km-sugar-blast",
    imgUrl: "./images/kingmakerGames/SugarBlast.jpg",
    alt: "SugarBlast",
    name: "Sugar Blast",
  },
  {
    redirectUrl: "/casino/km-teen-patti",
    imgUrl: "./images/kingmakerGames/Teenpatti.jpg",
    alt: "Teenpatti",
    name: "Teen Patti",
  },
  {
    redirectUrl: "/casino/km-tongits",
    imgUrl: "./images/kingmakerGames/Tongits.jpg",
    alt: "Tongits",
    name: "Tongits",
  },
];
