import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auraGames } from "../../../../lib/data/auraGames.data";
import BackIcon from "../../../../assets/svg/BackIcon";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

const Aura = () => {
  const navigate = useNavigate();
  const categoriesTab = [
    { name: "Improved Experience", key: "improvedExperience" },
    { name: "Card Games", key: "cardGames" },
    { name: "Virtual Card Games", key: "virtualCardGames" },
    { name: "Instant Games", key: "instantGames" },
  ];
  const [activeCategory, setActiveCategory] = useState("improvedExperience");
  const handleTabClick = (key) => {
    setActiveCategory(key);
  };
  const activeGames = Object.values(auraGames[activeCategory] || {});

  return (
    <>
      <HeaderAfterLogin />

      <main className="main aura-games">
        <div className="container">
          <div className="heading">
            <h4>AURA GAMES</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="games-section my-4">
            <ul className="categories">
              {categoriesTab.map((category) => (
                <li key={category.key}>
                  <a
                    href="javascript:void(0)"
                    onClick={() => handleTabClick(category.key)}
                    className={activeCategory === category.key ? "active" : ""}
                  >
                    {category.name}
                  </a>
                </li>
              ))}
            </ul>

            <ul className="all-games">
              {activeGames.map((game) => (
                <li key={game.code} className="game-item">
                  <a href={game.redirectUrl}>
                    <img src={game.imgUrl} alt={game.alt} />
                    <p>{game.name}</p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Aura;
