// import game1 from "../../../../assets/images/casino/TeenPatti.jpg";

import vivoAndarBahar from "../../assets/images/casino/vivoAndarBahar.jpg";
import vivoTeenPatti from "../../assets/images/casino/vivoTeenPatti.jpg";

import game1 from "../../assets/images/casino/TeenPatti.jpg";
import game2 from "../../assets/images/casino/betonteenpatti.jpg";
import game3 from "../../assets/images/casino/AndarBahar.jpg";
import game4 from "../../assets/images/casino/32Cards.jpg";
import game5 from "../../assets/images/casino/Roulette.jpg";
import game6 from "../../assets/images/casino/AkbarRomeoWalter.jpg";
import game7 from "../../assets/images/casino/Cricketwar.jpg";
import game8 from "../../assets/images/casino/Sicbo.jpg";
import game9 from "../../assets/images/casino/Baccarat.jpg";
import game10 from "../../assets/images/casino/WorliMatka.jpg";
import game11 from "../../assets/images/casino/DragonTiger.jpg";
import game12 from "../../assets/images/casino/OneDayTeenPatti.jpg";
import game13 from "../../assets/images/casino/KingRace.jpg";
import game14 from "../../assets/images/casino/CAsinoQueen.jpg";
import game15 from "../../assets/images/casino/ClassicAndarBahar.jpg";
import game16 from "../../assets/images/casino/Goa'sAndarBahar.jpg";
import game17 from "../../assets/images/casino/Lucky7.jpg";
import game18 from "../../assets/images/casino/Poker.jpg";
import game20 from "../../assets/images/casino/RNG3CardsJudgement.jpg";
import game21 from "../../assets/images/casino/RNG3-PlayerTeenPatti.jpg";
import game22 from "../../assets/images/casino/RNG32Cards.jpg";
import game23 from "../../assets/images/casino/RNGAkbarRomeoWalter.jpg";
import game24 from "../../assets/images/casino/RNGAndarBahar2020.jpg";
import game25 from "../../assets/images/casino/RNGBaccarat.jpg";
import game26 from "../../assets/images/casino/RNG-Baccarat-2020.jpg";
import game27 from "../../assets/images/casino/RNGCasinoQueen.jpg";
import game28 from "../../assets/images/casino/RNGCasinoQueen2020.jpg";
import game29 from "../../assets/images/casino/RNgCricket2020.jpg";
import game30 from "../../assets/images/casino/RNGDragonTiger.jpg";
import game31 from "../../assets/images/casino/RNGDragonTiger2020.jpg";
import game32 from "../../assets/images/casino/RNGJoker.jpg";
import game33 from "../../assets/images/casino/RNGKabutarFuddi.jpg";
import game34 from "../../assets/images/casino/RNGPoker.jpg";
import game35 from "../../assets/images/casino/RNGPoker2020.jpg";
import game36 from "../../assets/images/casino/RNGTeenPatti.jpg";
import game37 from "../../assets/images/casino/RNGTeenPatti2020.jpg";
import game38 from "../../assets/images/casino/TeenPattiFaceOff.jpg";

import populargame1 from "../../assets/images/casino/popular/oracle360roulette.jpg";
import populargame2 from "../../assets/images/casino/popular/frenchroulette.jpg";
import populargame3 from "../../assets/images/casino/popular/dragontigervivo.jpg";
import populargame4 from "../../assets/images/casino/popular/VABACCARAT1.jpg";
import populargame5 from "../../assets/images/casino/popular/lasvegasroulette.jpg";
import populargame6 from "../../assets/images/casino/popular/GonzosTreasureHunt.jpg";
import populargame7 from "../../assets/images/casino/popular/galaxybaccarat1.jpg";
import populargame8 from "../../assets/images/casino/popular/OttAndarBahar.jpg";
import populargame9 from "../../assets/images/casino/popular/Auto-Roulette.jpg";

import Ezugi from "../../assets/images/provider/Ezugi.jpg";
import EVOLUTION from "../../assets/images/provider/eVOLUTION.jpg";
import Vivo from "../../assets/images/provider/Vivo.jpg";
import WorldCasino from "../../assets/images/provider/WorldCasino.jpg";
import XPG from "../../assets/images/provider/XPG.jpg";
import Supernowa from "../../assets/images/provider/supernowanew.jpg";
import Slots from "../../assets/images/provider/Slots.jpg";
import Qtech from "../../assets/images/provider/Qtech.jpg";
import Powergame from "../../assets/images/provider/Powergame.png";
import Onetouch from "../../assets/images/provider/Onetouch.jpg";
import Aesexy from "../../assets/images/provider/Aesexy.png";
import Paragmatic from "../../assets/images/provider/Paragmatic.png";
import Aura from "../../assets/images/provider/Aura.png";
import Spribe from "../../assets/images/provider/Spribe.png";

export const gamesData = [
  {
    name: "Teen Patti Vivo",
    redirectUrl: "/casino/vivo-teen-patti",
    img: <img src={vivoTeenPatti} alt="vivo teen patti" />,
  },
  {
    name: "Andar Bahar Vivo",
    redirectUrl: "/casino/vivo-andar-bahar",
    img: <img src={vivoAndarBahar} alt="vivo andarbahar" />,
  },
  {
    name: "Teen Patti",
    redirectUrl: "/casino/ezg-teen-patti-3-card",
    img: <img src={game1} alt="teen patti 3 card" />,
  },
  {
    name: "Bet on Teen Patti",
    redirectUrl: "/casino/ezg-bet-on-teen-patti",
    img: <img src={game2} alt="bet on teen patti" />,
  },
  {
    name: "Andar Bahar",
    redirectUrl: "/casino/ezg-andar-bahar",
    img: <img src={game3} alt="andar bahar" />,
  },
  {
    name: "32 Cards",
    redirectUrl: "/casino/ezg-32-cards",
    img: <img src={game4} alt="32 cards" />,
  },
  {
    name: "spanish roulette",
    redirectUrl: "/casino/ezg-spanish-roulette",
    img: <img src={game5} alt="spanish roulette" />,
  },
  {
    name: "akbar romeo walter",
    redirectUrl: "/casino/sn-akbar-romeo-walter",
    img: <img src={game6} alt="akbar romeo walter" />,
  },
  {
    name: "Cricket war",
    redirectUrl: "/casino/ezg-cricket-war",
    img: <img src={game7} alt="cricket war" />,
  },
  {
    name: "Sicbo",
    redirectUrl: "/casino/ezg-sic-bo",
    img: <img src={game8} alt="sic bo" />,
  },
  {
    name: "Baccarat",
    redirectUrl: "/casino/ezg-baccarat",
    img: <img src={game9} alt="baccarat" />,
  },
  {
    name: "Worli matka",
    redirectUrl: "/casino/sn-rng-worli-matka",
    img: <img src={game10} alt="rng worli matka" />,
  },
  {
    name: "Dragon tiger",
    redirectUrl: "/casino/ezg-dragon-tiger",
    img: <img src={game11} alt="dragon tiger" />,
  },
  {
    name: "One Day Teen patti",
    redirectUrl: "/casino/ezg-one-day-teen-patti",
    img: <img src={game12} alt="one day teen patti" />,
  },
  {
    name: "King race",
    redirectUrl: "/casino/sn-rng-king-race",
    img: <img src={game13} alt="rng king race" />,
  },
  {
    name: "Online Casino queen",
    redirectUrl: "/casino/sn-rng-casino-queen",
    img: <img src={game14} alt="rng casino queen" />,
  },
  {
    name: "Classic Andar bahar",
    redirectUrl: "/casino/sn-classic-andar-bahar",
    img: <img src={game15} alt="classic andar bahar" />,
  },
  {
    name: "Goas Andar bahar",
    redirectUrl: "/casino/sn-goas-andar-bahar",
    img: <img src={game16} alt="goas andar bahar" />,
  },
  {
    name: "Lucky 7",
    redirectUrl: "/casino/ezg-lucky-7",
    img: <img src={game17} alt="lucky7" />,
  },
  // {
  //   name: "3 cards judgement",
  //   redirectUrl: "/casino/sn-rng-3-cards-judgement",
  //   img: <img src={game20} alt="rng 3 cards judgement"/>,
  // },
  {
    name: "Rng 3 player Teenpatti",
    redirectUrl: "/casino/sn-rng-3-player-teen-patti",
    img: <img src={game21} alt="rng 3 player teen patti" />,
  },
  {
    name: "32 cards",
    redirectUrl: "/casino/sn-rng-32-cards",
    img: <img src={game22} alt="rng 32 cards" />,
  },
  {
    name: "Rng Akbar romeo walter",
    redirectUrl: "/casino/sn-rng-akbar-romeo-walter",
    img: <img src={game23} alt="rng akbar romeo walter" />,
  },
  {
    name: "Rng Baccarat",
    redirectUrl: "/casino/sn-rng-baccarat",
    img: <img src={game25} alt="rng baccarat" />,
  },
  {
    name: "Rng Casino queen",
    redirectUrl: "/casino/sn-rng-casino-queen",
    img: <img src={game27} alt="rng casino queen" />,
  },
  {
    name: "Rng Casino queen 2020",
    redirectUrl: "/casino/sn-rng-casino-queen-2020",
    img: <img src={game28} alt="rng casino queen 2020" />,
  },
  // {
  //   name: "Rng Cricket 2020",
  //   redirectUrl: "/casino/sn-rng-cricket-2020",
  //   img: <img src={game29} alt="rng cricket 2020" />,
  // },
  {
    name: "Rng dragon tiger",
    redirectUrl: "/casino/sn-rng-dragon-tiger",
    img: <img src={game30} alt="rng dragon tiger" />,
  },
  {
    name: "Rng dragon tiger 2020",
    redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
    img: <img src={game31} alt="rng dragon tiger 2020" />,
  },
  {
    name: "Rng Joker",
    redirectUrl: "/casino/sn-rng-joker",
    img: <img src={game32} alt="rng joker" />,
  },
  {
    name: "Rng lucky 7",
    redirectUrl: "/casino/sn-rng-lucky-7",
    img: <img src={game33} alt="rng lucky 7" />,
  },
  // {
  //   name: "Rng poker",
  //   redirectUrl: "/casino/sn-rng-poker",
  //   img: <img src={game34} alt="rng poker" />,
  // },
  // {
  //   name: "Rng poker 2020",
  //   redirectUrl: "/casino/sn-rng-poker-2020",
  //   img: <img src={game35} alt="rng poker 2020" />,
  // },
  {
    name: "Rng teen patti",
    redirectUrl: "/casino/sn-rng-teen-patti",
    img: <img src={game36} alt="rng teen patti" />,
  },
  {
    name: "Rng teen patti 2020",
    redirectUrl: "/casino/sn-rng-teen-patti-2020",
    img: <img src={game37} alt="rng teen patti 2020" />,
  },
  {
    name: "teen patti Face off",
    redirectUrl: "/casino/sn-teen-patti-face-off",
    img: <img src={game38} alt="teen patti face off" />,
  },
  {
    name: "Oracle 360 Roulette",
    redirectUrl: "/casino/vivo-oracle-360-roulette",
    img: <img src={populargame1} alt="oracle 360 roulette" />,
  },
  {
    name: "French Roulette",
    redirectUrl: "/casino/vivo-french-roulette",
    img: <img src={populargame2} alt="french roulette" />,
  },
  {
    name: "Dragon Tiger Vivo",
    redirectUrl: "/casino/vivo-dragon-tiger",
    img: <img src={populargame3} alt="dragon tiger" />,
  },
  {
    name: "VA Baccarat 1",
    redirectUrl: "/casino/vivo-va-bacarat-1",
    img: <img src={populargame4} alt="va bacarat 1" />,
  },
  {
    name: "Las Vegas Roulette",
    redirectUrl: "/casino/vivo-las-vegas-roulette",
    img: <img src={populargame5} alt="las vegas roulette" />,
  },
  {
    name: "gonzos quest megaways",
    redirectUrl: "/casino/ezgrt-gonzos-quest-megaways",
    img: <img src={populargame6} alt="gonzos quest megaways" />,
  },
  {
    name: "Galaxy Baccarat 1",
    redirectUrl: "/casino/vivo-galaxy-baccarat-1",
    img: <img src={populargame7} alt="galaxy baccarat 1" />,
  },
  {
    name: "marina andar bahar",
    redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
    img: <img src={populargame8} alt="casino marina andar bahar" />,
  },
  {
    name: "Auto roulette",
    redirectUrl: "/casino/ezg-auto-roulette",
    img: <img src={populargame9} alt="auto roulette" />,
  },
  {
    name: "Cricket Auto roulette",
    redirectUrl: "/casino/ezg-cricket-auto-roulette",
    img: <img src={populargame9} alt="cricket auto roulette" />,
  },
];

export const providersData = [
  {
    name: "Ezugi",
    redirectUrl: "/casino/ezugi",
    img: <img src={Ezugi} alt="Ezugi" />,
  },
  {
    name: "Evolution",
    redirectUrl: "/casino/evolution",
    img: <img src={EVOLUTION} alt="EVOLUTION" />,
  },
  {
    name: "World Casino",
    redirectUrl: "/casino/worldcasino",
    img: <img src={WorldCasino} alt="WorldCasino" />,
  },
  {
    name: "Supernowa",
    redirectUrl: "/casino/supernowa",
    img: <img src={Supernowa} alt="Supernowa" />,
  },
  {
    name: "Vivo",
    redirectUrl: "/casino/vivo",
    img: <img src={Vivo} alt="Vivo" />,
  },
  {
    name: "XPG / XPro gaming",
    redirectUrl: "/casino/xpg",
    img: <img src={XPG} alt="XPG" />,
  },
  {
    name: "Slots",
    redirectUrl: "/slots",
    img: <img src={Slots} alt="Slots" />,
  },
  {
    name: "Qtech",
    redirectUrl: "/casino/qtech",
    img: <img src={Qtech} alt="Qtech" />,
  },
  {
    name: "Powergames",
    redirectUrl: "/casino/powergames",
    img: <img src={Powergame} alt="Powergames" />,
  },
  // {
  //   name: "One touch",
  //   redirectUrl: "/casino/onetouch",
  //   img: <img src={Onetouch} alt="One touch" />,
  // },
  {
    name: "Ae sexy / AWC",
    redirectUrl: "/casino/aesexy",
    img: <img src={Aesexy} alt="Ae sexy / AWC" />,
  },
  {
    name: "Pragmatic Play",
    redirectUrl: "/casino/pragmaticplay",
    img: <img src={Paragmatic} alt="Pragmatic Play" />,
  },
  {
    name: "Aura",
    redirectUrl: "/Aura",
    img: <img src={Aura} alt="Aura" />,
  },
  {
    name: "SPRIBE",
    redirectUrl: "/Spribe",
    img: <img src={Spribe} alt="SPRIBE" />,
  },
];
