import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import PlayBtn from "../../../../assets/images/play-btn.png";

import { useSelector } from "react-redux";
import BackIcon from "../../../../assets/svg/BackIcon";
import { useNavigate } from "react-router-dom";
import { spribeData } from "../../../../lib/data/spribe.data";

const Spribe = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  return (
    <>
      <HeaderAfterLogin />

      <main className="main spribe-games">
        <div className="slots-section">
          <div className="heading">
            <h4>Spribe</h4>
            <div className="back-link" onClick={() => navigate(-1)}>
              Back <BackIcon />
            </div>
          </div>

          <div className="games-section my-4">
            <ul className="all-games">
              {Object.values(spribeData).map((item, index) => (
                <li key={index}>
                  <a href={item.redirectUrl}>
                    <img src={item.imgUrl} alt={item.alt} />
                    <p>{item.name}</p>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Spribe;
